<template>
  <div class="base-list-page">
    <el-form
      :model="searchForm"
      @keyup.enter.native="getDataList()"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item class="typeInput" label="订单号">
          <el-input
            v-model="searchForm.orderSn"
            placeholder="订单号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item class="typeInput" label="用户名">
          <el-input
            v-model="searchForm.userName"
            placeholder="用户名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item class="typeInput" label="用户ID">
          <el-input
            v-model="searchForm.uuid"
            placeholder="用户ID"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item class="typeInput" label="员工姓名">
          <el-input
            v-model="searchForm.employeesName"
            placeholder="员工姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="充值日期" class="typeInput">
          <el-date-picker
            v-model="value"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="支付方式" class="typeInput">
          <el-select v-model="searchForm.payType" filterable clearable>
            <el-option
              v-for="(item, index) in payTypeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="充值来源" class="typeInput">
          <el-select v-model="searchForm.fromType" filterable clearable>
            <el-option
              v-for="(item, index) in fromTypeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList()">查询</el-button>
          <el-button type="primary" size="mini" @click="openReport()"
            >导出报表</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <div class="listBtn">
      <span>
        充值用户数：<b>{{ this.total.userCount }}</b>
      </span>
      <span>
        充值金额总计：<b>{{ this.total.totalRecharge }}</b>
      </span>
      <span>
        赠送金额总计：<b>{{ this.total.totalGift }}</b>
      </span>
      <span>
        到账金额总计：<b>{{ this.total.totalMoney }}</b>
      </span>
      <span>
        实付金额总计：<b>{{ this.total.totalPay }}</b>
      </span>
    </div>
    <el-table
      :data="dataList"
      stripe
      border
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="orderSn"
        header-align="center"
        align="center"
        label="充值订单号"
      >
      </el-table-column>

      <el-table-column
        prop="uuid"
        header-align="center"
        align="center"
        label="用户ID"
      >
      </el-table-column>

      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="充值用户"
      >
      </el-table-column>

      <el-table-column
        prop="employeesName"
        header-align="center"
        align="center"
        label="员工姓名"
      >
      </el-table-column>

      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="绑定手机号"
      >
      </el-table-column>

      <el-table-column
        prop="title"
        header-align="center"
        align="center"
        label="优惠活动名称"
      >
      </el-table-column>

      <el-table-column
        prop="rechargeMoney"
        header-align="center"
        align="center"
        label="充值金额"
      >
        <template slot-scope="scope">
          <el-tag type="success" effect="dark" size="small"
            >￥{{ scope.row.rechargeMoney }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="giftAmount"
        header-align="center"
        align="center"
        label="赠送金额"
      >
        <template slot-scope="scope">
          <el-tag type="success" effect="dark" size="small"
            >￥{{ scope.row.giftAmount }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="money"
        header-align="center"
        align="center"
        label="到账金额"
      >
        <template slot-scope="scope">
          <el-tag type="success" effect="dark" size="small"
            >￥{{ scope.row.money }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="realPay"
        header-align="center"
        align="center"
        label="实际支付"
      >
        <template slot-scope="scope">
          <el-tag effect="dark" size="small">￥{{ scope.row.realPay }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="payTypeName"
        header-align="center"
        align="center"
        label="支付方式"
      >
      </el-table-column>
      <el-table-column
        prop="rechargeTime"
        header-align="center"
        align="center"
        label="充值时间 "
      >
      </el-table-column>
      <el-table-column
        prop="fromType"
        header-align="center"
        align="center"
        label="充值来源"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.fromType === 1" type="success"
            >微信小程序</el-tag
          >
          <el-tag v-else-if="scope.row.fromType === 2" type="success"
            >微信公众号</el-tag
          >
          <el-tag v-else-if="scope.row.fromType === 3" type="success"
            >APP</el-tag
          >
          <el-tag v-else-if="scope.row.fromType === 4" type="success"
            >H5</el-tag
          >
          <el-tag v-else-if="scope.row.fromType === 5" type="success"
            >支付宝小程序</el-tag
          >
          <el-tag v-else-if="scope.row.fromType === 6" type="success"
            >点餐机</el-tag
          >
          <el-tag v-else-if="scope.row.fromType === 7" type="success"
            >点餐平台</el-tag
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
import { listMixin, normal } from '@/mixins';

export default {
  mixins: [listMixin, normal],

  data() {
    return {
      searchForm: {
        userName: '',
        employeesName: '',
        orderSn: '',
        startDate: '',
        endDate: '',
        payType: '',
        fromType: '',
        uuid: '',
      },
      total: {
        userCount: 0,
        totalRecharge: 0,
        totalGift: 0,
        totalMoney: 0,
        totalPay: 0,
      },
      payTypeList: [
        {
          id: 1,
          name: '微信支付',
        },
        {
          id: 9,
          name: '火炬园一卡通',
        },
        {
          id: 10,
          name: '点餐平台',
        },
        {
          id: 11,
          name: '公司',
        },
      ],
      fromTypeList: [
        {
          id: 1,
          name: '微信小程序',
        },
        {
          id: 6,
          name: '点餐机',
        },
        {
          id: 7,
          name: '点餐平台',
        },
      ],
      value: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      if (this.value?.length) {
        this.searchForm.startDate = this.value[0];
        this.searchForm.endDate = this.value[1];
      } else {
        this.searchForm.startDate = null;
        this.searchForm.endDate = null;
      }
      this.$http({
        url: '/tc/userrecharge/list',
        method: 'get',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
          userName: this.searchForm.userName,
          orderSn: this.searchForm.orderSn,
          startDate: this.searchForm.startDate,
          endDate: this.searchForm.endDate,
          payType: this.searchForm.payType,
          fromType: this.searchForm.fromType,
          uuid: this.searchForm.uuid,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list;
          this.total = data.total;
          this.totalPage = data.page.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    openReport() {
      if (this.value?.length) {
        this.searchForm.startDate = this.value[0];
        this.searchForm.endDate = this.value[1];
      } else {
        this.searchForm.startDate = '';
        this.searchForm.endDate = '';
      }
      window.open(`${process.env.VUE_APP_TABLE_BASE_URL}
      user_recharge.rptdesign&__showtitle=false&pageOverflow=2&__locale=zh_CN
      &order_sn=${this.searchForm.orderSn}&user_name=${this.searchForm.userName}
      &employees_name=${this.searchForm.employeesName}
      &start_date=${this.searchForm.startDate}&end_date=${this.searchForm.endDate}
      &pay_type=${this.searchForm.payType}&from_type=${this.searchForm.fromType}`);
    },
  },
};
</script>
